import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import TextInput from "../../../components/inputs/textInput/textInput";
import PasswordInput from "../../../components/inputs/passwordInput/passwordInput";
import { Button } from "../../../components/button/button";

import Logo from "../../../assets/images/myte-logo.png";

import { REGEX } from "../../../core/constants/regex";
import { Signin } from "../../../api-config/services/Auth.service";
import { GetUserDetails } from "../../../api-config/services/User.service";
import { setLocalStorage } from "../../../utils/utils";

import "./login.scss";

interface CustomButton extends HTMLButtonElement {
  setLoader: () => void;
}
interface FormData {
  email: string;
  password: string;
}
interface ErrorObject {
  email: string;
  message: string;
  resend_link: string;
}
interface Response {
  data: {
    access_token: string;
  };
  message?: string;
}

const Login = () => {
  const btnRef = useRef<CustomButton | null>(null);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const handleUnverifiedEmail = (errorObject: ErrorObject) => {
    navigate("/forgot-password", { state: errorObject });
  };

  const onSubmit = (data: FormData) => {
    btnRef.current?.setLoader();
    const { email, password } = data;
    Signin({ email, password })
      .then((res: unknown) => {
        const response = res as Response;
        setLocalStorage("access_token", response?.data?.access_token);
        GetUserDetails();
        navigate("/subscription");
        btnRef.current?.setLoader();
      })
      .catch((err) => {
        const errorObject: ErrorObject | undefined =
          err.response?.data?.errors?.error;
        errorObject?.resend_link && handleUnverifiedEmail(errorObject);
        btnRef.current?.setLoader();
      });
  };

  return (
    <div className="loginPageContainer transparent-scrollbar">
      <div className="logoContainer">
        <img src={Logo} alt="myte-logo" width={164.64} height={64} />
      </div>

      <div className="loginHeading">
        <h3 className="">Welcome to Myte Social FB</h3>
        <p className="">Hey there, down here 👋 Want to hear something cool?</p>
      </div>

      <div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: REGEX.EMAIL,
                message: "Invalid email address",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  placeHolder="Enter your email"
                  name="email"
                  id="email"
                  error={errors.email?.message}
                  fieldClass={"email-field"}
                />
              );
            }}
          />

          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
              pattern: {
                value: REGEX.PASSWORD,
                message:
                  "The password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and should be 8 to 12 characters long",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <PasswordInput
                  value={value}
                  onChange={onChange}
                  placeHolder="Enter your password"
                  name="password"
                  id="password"
                  // prefix={<LockIcon size="24" color="#181F2A" />}
                  error={errors.password?.message}
                  fieldClass="password-filed"
                />
              );
            }}
          />
          <div className="forgotLink">
            <Link to={"/forgot-password"}>Forgot password</Link>
          </div>

          <div className="btnWrapper">
            <Button
              ref={btnRef}
              disable={!isValid}
              title="Sign in"
              style="primary"
              type="submit"
            />
          </div>
        </form>

        <div className="signUpLink">
          <p>
            <span>Don’t have an account? Let’s </span>
            <Link to={"/signup"}>Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
