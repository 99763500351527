import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import Loader from "../../../components/loader/loader";

import { setLocalStorage } from "../../../utils/utils";
import { VerifyUserEmail } from "../../../api-config/services/Auth.service";
import { GetUserDetails } from "../../../api-config/services/User.service";

interface Response {
  data: {
    access_token: string;
  };
  message?: string;
}

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {
    token &&
      VerifyUserEmail(token)
        .then((res: unknown) => {
          const response = res as Response;
          setLocalStorage("access_token", response?.data?.access_token);
          GetUserDetails().then(
            (isSucceed) => isSucceed && navigate("/subscription")
          );
        })
        .catch((error) => {
          console.log(error);
          navigate("/login");
        });
  }, []);

  return <Loader />;
};

export default VerifyEmail;
