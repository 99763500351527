import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { Button } from "../../components/button/button";
import Loader from "../../components/loader/loader";
import EmptyPlaceholder from "../../components/empty-placeholder/EmptyPlaceholder";
// import Backup from "./backup/backup";

import WindowsIcon from "../../assets/images/windows.svg";
// import IOSIcon from "../../assets/images/ios.svg";
// import PauseIcon from "../../assets/svg/PauseIcon";

import { getLocalStorage } from "../../utils/utils";
import { DownloadPackage } from "../../api-config/services/User.service";

import "./package.scss";

const PackageAndBackup = () => {
  const [loading, setLoading] = useState(true);
  const [packageLink, setPackageLink] = useState("");

  const isSubscribed = getLocalStorage("isSubscribed") === "true";

  const getDownloadPackageLink = () => {
    DownloadPackage()
      .then((res) => {
        setPackageLink(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    isSubscribed ? getDownloadPackageLink() : setLoading(false);
  }, []);

  return (
    <div className="package-page-container transparent-scrollbar">
      {loading ? (
        <Loader showLogo={false} />
      ) : (
        <div className="package-inner-container">
          <div className="package-container">
            <p className="title">Myte Social FB</p>
            <p className="description">
              Packages that helps you to have better version than previous one
            </p>
            <Link
              to={isSubscribed ? packageLink : "/subscription"}
              className="button primary-outline"
            >
              {isSubscribed ? (
                <>
                  <img src={WindowsIcon} alt="windows" height={16} width={16} />
                  <p>Download for Windows OS</p>
                </>
              ) : (
                <p>Subscribe Now</p>
              )}
            </Link>
            {/* <Link to="" className="button primary-outline">
            <img src={IOSIcon} alt="ios" height={16} width={16} />
            <p>Download for Mac OS</p>
          </Link> */}
          </div>
          <div className="backup-detail-container">
            <div className="inline-items">
              <h2>Backup</h2>
              {/* <Button
                style="primary"
                title="Pause"
                icons={<PauseIcon fill="#FFFFFF" width={16} height={16} />}
              /> */}
            </div>
            <div className="backup-listing transparent-scrollbar">
              {/* {Array.from({ length: 20 }).map((_, index) => {
                return (
                  <Backup
                    key={index}
                    fileName={`Backup ${index + 1}`}
                    date={`${index + 1} March, 2024`}
                  />
                );
              })} */}
              <EmptyPlaceholder title="No Backup found" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageAndBackup;
