import Logo from "../../assets/images/logo.png";

import "./loader.scss";

const Loader = ({ showLogo = true }) => {
  return (
    <div className="loader-container">
      <div className="loader-inner-container">
        <div className="dbl-spinner"></div>
        <div className="dbl-spinner dbl-spinner--2"></div>
        {showLogo && (
          <img
            src={Logo}
            alt="loader"
            width={164.64}
            height={65}
            className="loader-logo"
          />
        )}
      </div>
    </div>
  );
};

export default Loader;
