import { TextInputProps } from "./type";

import "../input.scss";

const TextInput = (props: TextInputProps) => {
  return (
    <>
      <div
        className={`form-control  ${props.fieldClass ? props.fieldClass : ""}`}
      >
        {props.label && (
          <label className="input-label" htmlFor={props.id}>
            {props.label || ""}
            {!props.isNotRequired ? <span>*</span> : ""}
          </label>
        )}
        <input
          type={props.type || "text"}
          name={props.name || ""}
          id={props.id}
          placeholder={props.placeHolder}
          disabled={props.disabled || false}
          onChange={props.onChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={null}
          value={props.value}
          className={`common-input ${props.style ? props.style : ""} ${
            props.suffix ? "issuffix" : ""
          } ${props.preffix ? "ispreffix" : ""} `}
          autoFocus={props.autoFocus || false}
        />
        {props.preffix && (
          <span className="input-preffix">{props.preffix}</span>
        )}
        {props.error && <span className="error-message">{props.error}</span>}
        {props.suffix && <span className="input-suffix">{props.suffix}</span>}
      </div>
    </>
  );
};

export default TextInput;
