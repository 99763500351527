import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../../components/button/button";
import PasswordInput from "../../../components/inputs/passwordInput/passwordInput";

import { REGEX } from "../../../core/constants/regex";
import { showToastErrorMessage } from "../../../utils/toastify/Toastify";
import { clearLocalStorage } from "../../../utils/utils";
import {
  ResetPasswordCredentials,
  ResetPassword as initiateResetPassword,
} from "../../../api-config/services/Auth.service";
import {
  ChangePassword,
  UserCredentials,
} from "../../../api-config/services/User.service";

import "../forgotPassword/forgotPassword.scss";

interface CustomButton extends HTMLButtonElement {
  setLoader: () => void;
}
type Props = {
  isChangePassword?: boolean;
};
type FormData = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

const ResetPassword = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const submitRef = useRef<CustomButton | null>(null);

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const headerSnippet = props?.isChangePassword ? "Change" : "Reset";
  const extractToken = () => new URLSearchParams(location.search).get("token");

  const initResetPassword = (data: ResetPasswordCredentials) => {
    submitRef.current?.setLoader();
    initiateResetPassword(data)
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => submitRef.current?.setLoader());
  };
  const initChangePassword = (data: UserCredentials) => {
    submitRef.current?.setLoader();
    ChangePassword(data)
      .then((res) => {
        clearLocalStorage();
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        showToastErrorMessage(err?.response?.data?.message);
      })
      .finally(() => submitRef.current?.setLoader());
  };

  const onSubmit = ({ oldPassword, password }: FormData) => {
    const token = extractToken() || "";
    props?.isChangePassword
      ? initChangePassword({
          current_password: oldPassword,
          new_password: password,
        })
      : initResetPassword({ token, new_password: password });
  };

  return (
    <div className="forgotPasswordPageContainer transparent-scrollbar">
      <div className="forgotHeading">
        <h3>{headerSnippet} Password</h3>
        <p>
          Hey there👋, Please {headerSnippet.toLocaleLowerCase()} your password
          here
        </p>
      </div>

      <div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {props?.isChangePassword && (
            <Controller
              name="oldPassword"
              control={control}
              rules={{
                required: "This field cannot be empty",
                pattern: {
                  value: REGEX.PASSWORD,
                  message:
                    "The password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and should be 8 to 12 characters long",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <PasswordInput
                    value={value}
                    onChange={onChange}
                    placeHolder="Old Password"
                    name="oldPassword"
                    id="oldPassword"
                    error={errors.oldPassword?.message}
                    fieldClass="newPassword"
                  />
                );
              }}
            />
          )}

          <Controller
            name="password"
            control={control}
            rules={{
              required: "This field cannot be empty",
              pattern: {
                value: REGEX.PASSWORD,
                message:
                  "The password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and should be 8 to 12 characters long",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <PasswordInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue("password", e.target.value);
                    !props?.isChangePassword &&
                      getValues("confirmPassword") !== "" &&
                      trigger("confirmPassword");
                  }}
                  placeHolder="New Password"
                  name="password"
                  id="password"
                  error={errors.password?.message}
                  fieldClass="newPassword"
                />
              );
            }}
          />
          {!props?.isChangePassword && (
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Confirm password is required",
                validate: (value) =>
                  value == getValues("password") ||
                  "The passwords do not match",
              }}
              render={({ field: { value } }) => {
                return (
                  <PasswordInput
                    value={value}
                    onChange={(e) => {
                      setValue("confirmPassword", e.target.value, {
                        shouldValidate:
                          getValues("password") !== "" ? true : false,
                      });
                    }}
                    placeHolder="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    error={errors.confirmPassword?.message}
                    fieldClass="confirm-password-field"
                  />
                );
              }}
            />
          )}

          <div className="btnWrapper">
            <Button
              ref={submitRef}
              disable={!isValid}
              title="Continue"
              style="primary"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
