import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import TextInput from "../../../components/inputs/textInput/textInput";
import PasswordInput from "../../../components/inputs/passwordInput/passwordInput";
import { Button } from "../../../components/button/button";

import Logo from "../../../assets/images/myte-logo.png";

import { REGEX } from "../../../core/constants/regex";
import { Signup } from "../../../api-config/services/Auth.service";

import "./signUp.scss";

interface CustomButton extends HTMLButtonElement {
  setLoader: () => void;
}
interface FormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp = () => {
  const navigate = useNavigate();
  const btnRef = useRef<CustomButton | null>(null);

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
    control,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "all",
  });

  const onSubmit = (data: FormData) => {
    btnRef.current?.setLoader();
    const { email, password, name } = data;
    Signup({ email, password, full_name: name.trim() })
      .then((res) => {
        navigate("/login");
        reset();
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      })
      .finally(() => {
        btnRef.current?.setLoader();
      });
  };

  return (
    <div className="signUpPageContainer transparent-scrollbar">
      <div className="logoContainer">
        <img src={Logo} alt="myte-logo" width={164.64} height={64} />
      </div>

      <div className="signUpHeading">
        <h3 className="">Welcome to Myte Social FB</h3>
        <p className="">Hey there, down here 👋 Want to hear something cool?</p>
      </div>

      <div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required",
              // pattern: {
              //   value: REGEX.ALPHA_NUMERIC_WITH_SPACE,
              //   message: "Only alphanumeric is allowed",
              // },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  placeHolder="Enter full name"
                  name="name"
                  id="name"
                  error={errors.name?.message}
                  fieldClass={"name-field"}
                />
              );
            }}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: REGEX.EMAIL,
                message: "Invalid email address",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  placeHolder="Enter your email"
                  name="email"
                  id="email"
                  error={errors.email?.message}
                  fieldClass={"email-field"}
                />
              );
            }}
          />

          <Controller
            name="password"
            control={control}
            rules={{
              required: "This field cannot be empty",
              pattern: {
                value: REGEX.PASSWORD,
                message:
                  "The password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and should be 8 to 12 characters long",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <PasswordInput
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue("password", e.target.value);
                    getValues("confirmPassword") !== "" &&
                      trigger("confirmPassword");
                  }}
                  placeHolder="Enter your password"
                  name="password"
                  id="password"
                  // prefix={<LockIcon size="24" color="#181F2A" />}
                  error={errors.password?.message}
                  fieldClass="newPassword"
                />
              );
            }}
          />

          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Confirm password is required",
              validate: (value) =>
                value == getValues("password") || "The passwords do not match",
            }}
            render={({ field: { value } }) => {
              return (
                <PasswordInput
                  value={value}
                  onChange={(e) => {
                    setValue("confirmPassword", e.target.value, {
                      shouldValidate:
                        getValues("password") !== "" ? true : false,
                    });
                  }}
                  placeHolder="Confirm Password"
                  name="confirmPassword"
                  id="confirmPassword"
                  error={errors.confirmPassword?.message}
                  fieldClass="confirm-password-field"
                />
              );
            }}
          />

          <div className="btnWrapper">
            <Button
              ref={btnRef}
              disable={!isValid}
              title="Sign Up"
              style="primary"
              type="submit"
            />
          </div>
        </form>

        <div className="signInLink">
          <p>
            <span>Already have an account? Let’s </span>
            <Link to={"/"}>Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
