import "./ToasterSuccessIcon.scss";

export const ToasterSuccessIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#2AAD6C" />
      <path
        d="M27.8049 15.5999C27.4311 15.2255 26.825 15.2255 26.4513 15.5999L17.9986 24.0679L13.5491 19.6103C13.1753 19.2359 12.5692 19.2359 12.1954 19.6103C11.8216 19.9848 11.8216 20.592 12.1954 20.9665L17.3217 26.1021C17.5086 26.2893 17.7536 26.3829 17.9985 26.3829C18.2435 26.3829 18.4885 26.2893 18.6753 26.1021L27.8049 16.956C28.1787 16.5816 28.1787 15.9744 27.8049 15.5999Z"
        fill="white"
      />
    </svg>
  );
};
