import axios from "axios";
import { getLocalStorage } from "../utils/utils";

export const http = {
  post: axios.post,
  get: axios.get,
  delete: axios.delete,
  patch: axios.patch,
  put: axios.put,
};

export const setupInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log("error: ", error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    (config) => {
      // config.timeout = 10000;
      config.headers.Authorization = `Bearer ${getLocalStorage(
        "access_token"
      )}`;
      return Promise.resolve(config);
    },
    (error) => {
      console.log("error: ", error);
      return Promise.reject(error);
    }
  );
};
