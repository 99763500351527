import React from 'react';

interface EyeIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const defaultProps: EyeIconProps = {
  width: 24,
  height: 24,
  color: '#000000',
};

const EyeIcon = ({ width, height, color }: EyeIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12566 12.148C9.12566 10.6036 10.318 9.35166 11.7888 9.35166C13.2596 9.35166 14.452 10.6036 14.452 12.148C14.452 13.6923 13.2596 14.9443 11.7888 14.9443C10.318 14.9443 9.12566 13.6923 9.12566 12.148ZM10.8625 12.148C10.8625 12.6851 11.2772 13.1206 11.7888 13.1206C12.3004 13.1206 12.7151 12.6851 12.7151 12.148C12.7151 11.6108 12.3004 11.1753 11.7888 11.1753C11.2772 11.1753 10.8625 11.6108 10.8625 12.148Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2425 7.00519L22.0825 11.0659C22.3955 11.329 22.5776 11.7271 22.5776 12.148C22.5776 12.5689 22.3955 12.967 22.0825 13.23L17.2425 17.2908C14.0525 19.9643 9.52511 19.9643 6.33513 17.2908L1.49513 13.23C1.18217 12.967 1 12.5689 1 12.148C1 11.7271 1.18217 11.329 1.49513 11.0659L6.33513 7.00519C9.52511 4.3316 14.0525 4.3316 17.2425 7.00519ZM7.41197 15.9291C9.9737 18.0699 13.6039 18.0699 16.1657 15.9291L20.5888 12.148L16.1657 8.42766C13.6039 6.28685 9.9737 6.28685 7.41197 8.42766L2.98881 12.148L7.41197 15.9291Z"
        fill={color}
      />
    </svg>
  );
};

EyeIcon.defaultProps = defaultProps;

export default EyeIcon;
