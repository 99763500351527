import { apiEndpoints, makeRequest } from "../../api-config/api-config";
import { setLocalStorage } from "../../utils/utils";

interface UserObject {
  data: {
    _id: string;
    email: string;
    email_verified: boolean;
    password?: string;
    reset_token: string | null;
    verify_token: string | null;
  };
  message?: string;
}
export interface UserCredentials {
  current_password: string;
  new_password: string;
}
export interface DownloadPackageResponse {
  data: string;
  message?: string;
}

export const GetUserDetails = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.user.userDetails}`;
  const res = (await makeRequest("get", url, null, false)) as UserObject;
  if (res?.data) {
    const { _id, email, email_verified, reset_token, verify_token } = res?.data;
    setLocalStorage("user_id", _id);
    setLocalStorage("email", email);
    setLocalStorage("email_verified", email_verified.toString());
    reset_token && setLocalStorage("reset_token", reset_token);
    verify_token && setLocalStorage("verify_token", verify_token);
    return true;
  } else {
    return false;
  }
};

export const ChangePassword = async (data: UserCredentials) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.user.changePassword}`;
  return makeRequest("post", url, data);
};

export const DownloadPackage = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.user.downloadPackage}`;
  return makeRequest(
    "get",
    url,
    null,
    false
  ) as Promise<DownloadPackageResponse>;
};
