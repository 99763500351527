import { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import TextInput from "../../../components/inputs/textInput/textInput";
import { Button } from "../../../components/button/button";

import { REGEX } from "../../../core/constants/regex";
import {
  ResendVerificationEmail,
  ForgotPassword as initiateForgotPassword,
} from "../../../api-config/services/Auth.service";
import { showToastErrorMessage } from "../../../utils/toastify/Toastify";

import "./forgotPassword.scss";

interface CustomButton extends HTMLButtonElement {
  setLoader: () => void;
}

const ForgotPassword = () => {
  const btnRef = useRef<CustomButton | null>(null);
  const submitRef = useRef<CustomButton | null>(null);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });

  const setButtonLoader = (isSubmit: boolean) => {
    if (isSubmit) {
      submitRef.current?.setLoader();
    } else {
      btnRef.current?.setLoader();
      setLoading(false);
    }
  };

  const initForgotPassword = (email: string, isSubmit: boolean = true) => {
    isSubmit && submitRef.current?.setLoader();
    initiateForgotPassword({ email })
      .then((res) => {
        !linkSent && setLinkSent(true);
      })
      .catch((err) => {
        console.log(err);
        showToastErrorMessage(
          err?.response?.data?.error ||
            "Error while sending email, Please try again."
        );
      })
      .finally(() => {
        setButtonLoader(isSubmit);
      });
  };

  const resendVerificationEmail = () => {
    setLoading(true);
    btnRef.current?.setLoader();
    if (state?.resend_link) {
      ResendVerificationEmail({ email: state?.email })
        .then(() => navigate("/login"))
        .finally(() => {
          setButtonLoader(false);
        });
    } else {
      const email = getValues("email");
      initForgotPassword(email, false);
    }
  };

  const onSubmit = ({ email }: { email: string }) => {
    initForgotPassword(email);
  };

  const SendAgain = () => (
    <span
      className={loading ? "loading" : ""}
      onClick={resendVerificationEmail}
    >
      Send again
    </span>
  );

  return (
    <div className="forgotPasswordPageContainer transparent-scrollbar">
      <div className="forgotHeading">
        <h3>
          Oops!{" "}
          {!state?.resend_link
            ? "Forget Password?"
            : "Email ID is not verified!"}
        </h3>
        <p>
          {state?.resend_link ? (
            <>
              Please verify the email address! If you didn't get the
              verification email, please click to get it again? <SendAgain />
            </>
          ) : linkSent ? (
            <>
              We’ve sent an confirmation link on your email id. Kindly open your
              email to reset password. Didn’t Received? <SendAgain />
            </>
          ) : (
            "Hey there, down here 👋 Want to hear something cool?"
          )}
        </p>
      </div>

      <div>
        {!linkSent && !state?.resend_link && (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required.",
                pattern: {
                  value: REGEX.EMAIL,
                  message: "Invalid email address",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextInput
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    placeHolder="Enter your email"
                    name="email"
                    id="email"
                    error={errors.email?.message}
                    fieldClass={"email-field"}
                  />
                );
              }}
            />

            <div className="btnWrapper">
              <Button
                ref={submitRef}
                disable={!isValid}
                title="Continue"
                style="primary"
                type="submit"
              />
            </div>
          </form>
        )}

        {(linkSent || state?.resend_link) && (
          <Button
            ref={btnRef}
            disable={(!isValid && !state?.resend_link) || loading}
            title="Back to Login"
            style="primary"
            type="button"
            onClick={() => navigate("/")}
          />
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
