import { http } from "../config/http";
import {
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../utils/toastify/Toastify";

export const makeRequest = async (
  method: "get" | "post" | "put" | "delete",
  url: string,
  data: any = null,
  showToast: boolean = true
) => {
  return new Promise((resolve, reject) => {
    http[method](url, data)
      .then((response: any) => {
        showToast && showToastSuccessMessage(response.data.message);
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        showToastErrorMessage(error?.response?.data?.errors?.error);
        reject(error);
      });
  });
};

// ---------------------------------API_ENDPOINTS---------------------------------

export const apiEndpoints = {
  auth: {
    signup: "/auth/signup",
    signin: "/auth/login",
    resendVerificationEmail: "/auth/resend-verification-email",
    verifyEmail: "/auth/verify-email",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
  },
  user: {
    userDetails: "/user/profile",
    changePassword: "/user/change-password",
    downloadPackage: "/user/download-package",
  },
  subscription: {
    verifySubscription: "/stripe/verify-subscription",
    createPaymentIntent: "/stripe/create-payment-intent",
    createBillingSession: "/stripe/create-billing-session",
  },
};
